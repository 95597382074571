import { Toast, Dialog } from 'vant'

export default http => {
  http.interceptors.request.use(
    config => {
      if (config.url.includes('app')) {
        config.headers.Call_Source = 'health_app'
      } else {
        config.headers.Call_Source = 'health_assessment'
      }
      const token = window.localStorage.getItem('accessToken')
      config.headers['Authorization'] = token
      Toast.loading({ duration: 0 })
      return config
    },
    error => {
      return Promise.reject(error)
    },
  )
  http.interceptors.response.use(
    response => {
      Toast.clear()
      const res = response.data
      // token失效，重新登录
      if (res.code == 401) {
        Dialog.alert({
          message: '登录已失效,请重新登录',
          confirmButtonColor: '#3350c7',
        }).then(() => {
          const url = window.location.origin
          window.location.href = url
        })
      }
      return res
    },
    error => {
      Toast.clear()
      if (!error['response']) {
        return Promise.reject(error)
      }
      switch (error.response.status) {
        case 401:
          break
        case 500:
        case 501:
        case 503:
        default:
      }
      return Promise.reject(error.response)
    },
  )
}
