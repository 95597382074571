import axios from 'axios'
import interceptors from './interceptors'
const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 30000,
  headers: {
    Accept: 'application/json;charset=UTF-8',
    'Content-Type': 'application/json;charset=UTF-8',
  },
  transformRequest: [
    function (data) {
      data = data || {}
      return JSON.stringify(data)
    },
  ],
})
interceptors(http)
export default http
